
import {
  defineComponent,
  reactive,
  toRefs,
  onMounted,
  computed,
  ref,
} from "vue";
import { Api } from "@/assets/Api";
import { ElMessage, ElMessageBox } from "element-plus";
import moment from "moment";
import OrderLog from "./VisitOrderLog.vue";
import store from "@/store";

interface Column {
  label: string;
  prop: string;
  search: boolean;
  regex: boolean;
  rules: [];
}
export default defineComponent({
  name: "VisitOrder",
  components: { OrderLog },
  setup() {
    let data = reactive<any>({
      init: false,
      maps: new Map(),
      data: {},
      option: {},
      page: {
        total: 0,
        currentPage: 1,
        pageSize: 10,
        pageSizes: [10, 20],
        layout: "total, sizes, prev, pager, next",
      },
      query: {
        limit: 10,
        page: 1,
        where: { ServiceId: 9 },
        sort: "-OrderId",
      },
      logDialogVisible: false,
    });

    const OrderLogRef = ref<InstanceType<typeof OrderLog>>();
    const SetLog = (row: any) => {
      data.logDialogVisible = true;
      OrderLogRef.value?.searchChange(row.OrderId);
    };

    const goDoOrder = (Value: any) => {
      doOrder(Value.row, Value.dotype, "");
    };
    const crud = ref();
    const doOrder = (row: any, dotype: any, index: any) => {
      if (dotype == -1) {
        switch (data.role) {
          case "Hospital":
            SetLog(row);
            break;
          default:
            if (row.Status == 0 || row.Status == 1 || row.Status == 3)
              SetOrderStatus(row, 8);
            else SetLog(row);
            break;
        }
      } else {
        switch (dotype) {
          case 9:
            SetLog(row);
            break;
          case 10:
            console.log(row, index);

            crud.value.rowEdit(row, index);
            // this.order = row;
            // this.orderDialogVisible = true;
            break;
          default:
            SetOrderStatus(row, dotype);
            break;
        }
      }
    };

    const SetOrderStatus = (row: any, status: any) => {
      let vstatus = "";
      switch (status) {
        case 1:
          vstatus = "订单受理";
          break;
        case 5:
          vstatus = "订单完成";
          break;
        case 6:
          vstatus = "订单未完成";
          break;
        case 8:
          vstatus = "用户取消";
          break;
      }
      ElMessageBox.prompt(
        `请确认（${vstatus}${row.UserName}用户${row.UserDate}的订单）并输入备注`,
        `${vstatus}确认`,
        {
          confirmButtonText: "确定",
          cancelButtonText: "取消",
        }
      )
        .then(({ value }) => {
          const action = {
            status: status,
            remark: value ?? "",
          };
          Api.Service.SetOrderStatus(row.OrderId, action).then((res: any) => {
            if (res.Code == 0) {
              ElMessage.success("保存成功");
              getList();
            } else {
              ElMessage.warning({
                showClose: true,
                duration: 10000,
                message: res.ResultMsg,
              });
            }
          });
        })
        .catch(() => {
          ElMessage({
            type: "info",
            message: "Input canceled",
          });
        });
    };

    const OrderValue = (row: any, dotype: any, index: any) => {
      //if (dotype == 9) crud.value.rowEdit(row, index);
      return {
        row: row,
        dotype: dotype,
        index: index,
      };
    };

    const menuButtonTitle = computed(() => (status: number) => {
      switch (data.role) {
        case "Hospital":
          return "查看记录";
        default:
          if (status == 0 || status == 1) return "用户取消";
          else return "查看记录";
      }
    });

    const menuButtonDropdown = computed(() => (status: number) => {
      const dropdown = [];
      switch (data.role) {
        case "third":
          if (status == 0) {
            dropdown.push({ title: "订单受理", do: 1 });
          }
          dropdown.push({ title: "查看记录", do: 9 });
          break;
        case "gh":
          if (status == 0 || status == 1) {
            dropdown.push({ title: "修改患者信息", do: 10 });
          }
          dropdown.push({ title: "查看记录", do: 9 });
          break;
        default:
          if (status == 0) {
            dropdown.push({ title: "订单受理", do: 1 });
          } else if (status == 1) {
            dropdown.push({ title: "完成订单", do: 5 });
            dropdown.push({ title: "订单未完成", do: 6 });
          }
          if (status == 0 || status == 1) {
            dropdown.push({ title: "修改患者信息", do: 10 });
          }
          dropdown.push({ title: "查看记录", do: 9 });
          break;
      }
      return dropdown;
    });

    const searchChange = (params: any, done: any) => {
      for (let p in params) {
        const field: Column = data.option.column.find(
          (v: Column) => v.prop === p
        );
        if (field.regex) {
          params[p] = { $regex: params[p] };
        }
      }
      data.query.where = params;
      data.page.currentPage = 1;
      data.query.page = 1;
      data.query.limit = data.page.pageSize;
      getList();
      done();
    };

    const changePage = ({ pageSize, currentPage }: any) => {
      data.query.page = currentPage;
      data.query.limit = pageSize;
      getList();
    };

    const getList = () => {
      console.log(data.query);
      Api.Service.GetVisitOrderList(data.query).then((res: any) => {
        console.log(res);
        data.data = {};
        if (res.Code == 0) {
          data.data = res.Data;
          data.page.total = res.Data.total;
          data.page.currentPage = res.Data.page;
          console.log(data.data.data);
        } else {
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const getOption = () => {
      Api.Service.GetVisitOrderOption().then((res: any) => {
        console.log(res);
        data.option = res;
        data.init = true;
      });
    };

    const toDate = computed(() => (dt: any) => {
      return moment(dt).format("YYYY-MM-DD");
    });
    const vStatus = computed(() => (Status: any) => {
      switch (Status) {
        case 0:
          return "未确认";
        case 1:
          return "已确认";
        case 5:
          return "已完成";
        case 6:
          return "未完成";
        case 8:
          return "已取消";
        default:
          return "未知";
      }
    });

    const rowSave = (form: any, done: any, loading: any) => {
      Api.Service.AddVisitOrder(form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    const rowUpdate = (form: any, index: any, done: any, loading: any) => {
      Api.Service.EditVisitOrder(form.OrderId, form).then((res: any) => {
        if (res.Code == 0) {
          ElMessage.success("保存成功");
          getList();
          done();
        } else {
          loading();
          ElMessage.warning({
            showClose: true,
            duration: 10000,
            message: res.ResultMsg,
          });
        }
      });
    };

    onMounted(() => {
      data.role = store.getters.role;
      getOption();
    });

    return {
      ...toRefs(data),
      searchChange,
      changePage,
      rowSave,
      rowUpdate,
      toDate,
      vStatus,
      crud,
      doOrder,
      goDoOrder,
      menuButtonTitle,
      menuButtonDropdown,
      OrderLogRef,
      OrderValue,
    };
  },
});
